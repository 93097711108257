<template>
  <section v-if="loaded">
    <vue-headful :title="`FLPN Aviasolutions - ${pageTitle}`"></vue-headful>
    <h1 class="title">{{ pageTitle }}</h1>
    <contenedor>
      <b-tabs v-model="activeTab" @change="onChangeTab">
        <b-tab-item label="Aircraft">
          <aircraft :propFleets="fleetGroups" :flightPlan="flightPlan"></aircraft>
        </b-tab-item>
        <b-tab-item label="Flight">
          <FlightPlanning :flightPlan="flightPlan" :client="client"></FlightPlanning>
        </b-tab-item>
        <b-tab-item label="Summary">
          <PlanSummary :flightPlan="flightPlan" :plan="result"></PlanSummary>
        </b-tab-item>
        <b-tab-item :label="'Report (Plan #'+resultId+')'" v-if="hasResults">
          <PlanReport :flightPlan="flightPlan" :result="result"></PlanReport>
        </b-tab-item>
        <b-tab-item :label="'Iterations Detail Plan #'+resultId"
                    v-if="$auth.check(['admin']) && hasResults">
          <IterationsDetail v-if="result.iteration_logs" :result="result"></IterationsDetail>
        </b-tab-item>
      </b-tabs>
    </contenedor>
  </section>
</template>

<script>
import FleetsService from "../../services/fleets.service";
import Aircraft from "./Aircraft.vue";
import AircraftModel from "../../models/aircraft/aircraft.model";
import FlightPlanning from "./FlightPlanning.vue";
import PlanSummary from "./PlanSummary.vue";
import PlanReport from "./PlanReport.vue";
import IterationsDetail from "./IterationsDetail.vue";
import {EventBus} from '../../event-bus.js';
import PlanHelper from "../../classes/plan/plan-helper.js";
import ClientsService from "../../services/clients.service";
import ScheduledFlightsService from "../../services/scheduled-flights.service.js";
import Contenedor from "@/components/Contenedor";
import {mapActions, mapGetters, mapState} from "vuex";


export default {
  data() {
    return {
      pageTitle: 'Create Plan',
      fleets: [],
      fleetGroups: [],
      loaded: false,
      client: {},
      fleetsService: new FleetsService(),
      activeTab: 0,
      flightPlan: (new PlanHelper()).createEmptyFlightPlan(),
      clientsService: new ClientsService(),
      scheduledFlightsService: new ScheduledFlightsService()
    };
  },
  mounted: function () {
    this.resetResult();
    this.fleetsService.selectors().then(
        ({data}) => {
          this.fleetGroups = data.fleet_groups;
          this.flightPlan.aircraftTab.fleets = this.fleetGroups;
          if (this.$route.params.scheduledFlightId) {
            const loadingComponent = this.$buefy.loading.open({container: null})
            this.flightPlan.scheduled_flight_id = this.$route.params.scheduledFlightId;
            this.scheduledFlightsService.findOne(this.$route.params.scheduledFlightId).then(({data}) => {
              this.flightPlan.aircraftTab.fleet = this.getObjectById(this.fleetGroups, data.data.fleet_id);
              this.flightPlan.flightTab.alternateAirports.airports = [];
              this.flightPlan.validations.alternateAirports = [];
              this.flightPlan.flightTab.alternateAirports.display_alternate_navigation = false;
              this.flightPlan.flightTab.alternateAirports.display_alternate_header = false;
              this.flightPlan.aircraftTab.aircraft = new AircraftModel();
              this.flightPlan.flightTab.flight.eta = data.data.scheduledFlights[0].eta;
              this.flightPlan.flightTab.flight.etd = data.data.scheduledFlights[0].etd;
              this.flightPlan.flightTab.flight.route_id = data.data.scheduledFlights[0].route_id;
              this.flightPlan.flightTab.flight.flight_level = data.data.scheduledFlights[0].flight_level;
              this.flightPlan.flightTab.flight.route = data.data.scheduledFlights[0].route;
              this.flightPlan.flightTab.flight.flight_number = data.data.flight_number;
              this.flightPlan.flightTab.alternateAirports.airports.push(data.data.scheduledFlights[0].alternateAirports[0]);

              this.flightPlan.validations.alternateAirports[0] = {
                routeIsValid: true,
                flightLevelIsValid: true
              };
              if (data.data.scheduledFlights[0].alternateAirports.length > 1) {
                this.flightPlan.flightTab.alternateAirports.airports[1] = data.data.scheduledFlights[0].alternateAirports[1];
                this.flightPlan.validations.alternateAirports[1] =
                    {routeIsValid: true, flightLevelIsValid: true};
              }
              this.flightPlan.validations.alternateAirportsAreValid = true;
              this.fleetsService.listAircrafts(data.data.fleet_id).then(
                  ({data}) => {
                    // AIRCRAFT
                    this.flightPlan.aircraftTab.aircrafts = AircraftModel.createMany(data.aircrafts);
                    this.flightPlan.validations.routeIsValid = true;
                    this.flightPlan.validations.flightLevelIsValid = true;
                    this.loaded = true;
                    loadingComponent.close();
                    // this.getSpeedData()
                  },
                  () => {
                    loadingComponent.close();
                  }
              );
            });
          } else {
            this.loaded = true;
          }
        }
    );
    this.clientsService.findOne(this.$auth.user().client_id).then(
        ({data}) => {
          this.client = data;
        },
        () => {
        }
    );

  },
  components: {
    Contenedor,
    Aircraft,
    FlightPlanning,
    PlanSummary,
    PlanReport,
    IterationsDetail
  },
  watch: {
    'flightPlan.validations': {
      handler: function (val) {
        this.flightPlan.isValid = !(JSON.stringify(val).includes(`false`));
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['resetResult']),
    onChangeTab(value) {
      if (value === 2) {
        EventBus.$emit(
            'onUpdatePlanSummary',
            {}
        );
      }
    },
    getObjectById(array, id) {
      const entries = Object.entries(array);
      const groupFound = entries.find((entry) => {
        return entry[1].find((e) => e.id === id)
      })
      if(groupFound) {
        return groupFound[1].find((f) => f.id === id);
      }
      return null;
    },
  },
  computed: {
    ...mapState({
      result: state => state.planResults.result
    }),
    ...mapGetters(['hasResults', 'resultId']),
  }
}
;
</script>
<style src="../../assets/css/print.css" media="print"></style>
<style>
.dangertab .ul .li .a {
  color: red;
  font-size: 50px;
}
</style>